export const ROLE = {
  AGENT: 3,
  USER: 4,
  COMPANY: 5,
  AFFILIATE: 7,
};

export const MEDIA_TYPES = {
  IMAGE: 1,
  VIDEO: 2,
  FILE: 3,
  TEXT: 4,
};

export const PlanType = {
  standard: 1,
  premium: 3,
  featured: 2,
};

export const PROPERTY_APPROVAL_STATUS: { [key: number]: string } = {
  1: 'Pending',
  2: 'Approved',
  3: 'Rejected',
};
export const TOKEN_APPROVAL_STATUS: { [key: number]: string } = {
  1: 'Pending',
  2: 'Approved',
  3: 'Completed',
  4: ' Rejected',
};

export const APPROVAL_STATUS = {
  PENDING: 1,
  APPROVE: 2,
  REJECTED: 3,
};

export const RENT_TYPE = {
  SHORT_TERM: '1',
  WEEKLY: '2',
  YEARLY: '3',
  ANY: '4',
};

export const COMPLETION_STATUS = {
  Ready: '2',
  'Off-Plan': '3',
  Any: '1',
};

const STATUS_STYLES: { [key: number]: any } = {
  1: { borderColor: 'yellow', color: 'yellow' },
  2: { borderColor: 'green', color: 'green' },
  3: { borderColor: 'red', color: 'red' },
};

export const PLAN_TYPE: { [key: number]: string } = {
  1: 'Standard Listing',
  2: 'Featured Listing',
  3: 'Premium Listing',
};

export const PLAN_STYLES: { [key: number]: any } = {
  1: { borderColor: '#092676', color: '#092676' }, // Standard Listing (Gray)
  2: { borderColor: '#085CF0', color: '#085CF0' }, // Feature Listing (Yellow)
  3: { borderColor: '#FF5B23', color: '#FF5B23' }, // Premium Listing (Green)
};

export const TAB_NAMES: { [key: number]: string } = {
  0: 'My Listing',
  1: 'My Assigned Properties',
  2: 'My Drafts',
  3: 'All Listing',
  4: 'Listing Tracker',
  5: 'Delisted',
};

export const LISTED_PROPERTY_TYPE: { [key: string]: number } = {
  PUBLISHED: 1,
  UNPUBLISHED: 2,
  UNDER_OFFER: 3,
  ARCHIVE: 5,
  TEMPUNPUBLISH: 4,
};

export const DOCUMENT_TYPE = {
  TRADE_LICENCE: 1,
  RERA_REGISTRATION: 2,
  BROKERAGE_LICENCE: 3,
  EMIRATED_ID_OR_PASSWORD_COPIES: 4,
  POWER_OF_ATTORNEY: 5,
  AGENT_DOCS: 6,
  UNDER_OFFER_MOU: 7,
  FORM_F: 8,
  NEW_TITLE_DEED: 9,
  OLD_TITLE_DEED: 10,
  EJARI_CONTRACT: 11,
  TITLE_DEED: 12,
  MOU_OR_FORM_F: 13,
  TITLE_DEED_OR_SPA: 14,
  TENANCY_AGREEMENT: 15,
  EMIRATES_ID: 17,
  ADREC_OR_DARI_CARD: 19,
  LABOUR_OR_EMPLOYMENT_VISA: 20,
  SPA: 21,
};

export const DOC_TYPE_PARENT: { [key: string]: number } = {
  AGENT: 1,
  AGENCY: 2,
  UNDER_OFFER: 3,
  CLAIM_DOCS: 4,
};

export const FURNISHED_TYPE = {
  UNSELECTED: 0,
  FURNISHED: 1,
  UNFURNISHED: 2,
  PARTLY_FURNISHED: 3,
};

export const SUBSCRIPTION_PACK: Record<number, string> = {
  1: 'Monthly',
  3: 'Quarterly',
  6: 'Half Yearly',
  12: 'Yearly',
};
export const SUBSCRIPTION_PACK_TYPE_NAME: Record<number, string> = {
  1: 'Trail',
  3: 'Normal',
};

export enum BANNER_TYPE {
  LONG = '1',
  SHORT = '2',
}

export enum ADMIN_APPROVED_STATUS {
  APPROVED = 2,
  PENDING = 1,
  REJECTED = 3,
}

export enum PROPERTY_MODE {
  BUY = 1,
  RENT = 2,
  UNDER_CONSTRUCTION = 3,
}

export const Card_status = {
  2: 'Completed',
  3: 'Under Construction',
  1: 'Any',
} as any;

export enum AGENCY_TYPE {
  REAL_ESTATE_BROKER = 1,
  SHORT_TERM_RENTAL = 2,
}

export const optionsArray = [
  {
    id: 1,
    value: 'property_available_from',
  },
  {
    id: 2,
    value: 'property_available_to',
  },
  {
    id: 3,
    value: 'floor_plan',
  },
  {
    id: 4,
    value: 'service_charges',
  },
  {
    id: 5,
    value: 'financial_status',
  },
  {
    id: 6,
    value: 'furnishing_type',
  },
  {
    id: 7,
    value: 'deliveryDate',
  },
];

export enum LONG_TERM_RENT_TYPE {
  Cheque_1 = 1,
  Cheque_2 = 2,
  Cheque_3 = 3,
  Cheque_4 = 4,
}

export enum LeadType {
  PHONE = 1,
  IN_APP_CHAT = 2,
  EMAIL = 3,
  WHATSAPP = 4,
  ADS = 5,
}

export const leadTypeMapping: { [key: number]: number } = {
  0: 3, // Email
  1: 4, // WhatsApp
  2: 1, // Call
  3: 2, // Chat
  4: 6, // manual
};

export const PROFILE_STEPS = {
  PROFILE_SETUP: 1,
  DOCUMENTS_UPLOAD: 2,
};

export const PAYMENT_TYPES = {
  //for which type, the payment is being done
  TOP_UP_TOKENS: 1,
  BOOST_PLAN: 2,
  SUBSCRIPTION: 3,
};

export const SUBSCRIPTION_PACK_TYPE = {
  Trial: 1,
  Normal: 2,
};

export const SAVED_TYPE = {
  ALERT: 1,
  SAVED_FILTERS: 2,
};

export const BOOST_PLAN_TYPE = {
  APEX_BOOST: 3,
  ELEVATE_BOOST: 2,
  SPOTLIGHT_BOOST: 1,
};

export const BOOST_PLAN_TYPE_NAME: any = {
  3: 'Apex Boost',
  2: 'Elevate Boost',
  1: 'Spotlight Boost',
};

export const claimDocs = [
  {
    name: 'Form F',
    type: DOCUMENT_TYPE.FORM_F,
    parent: DOC_TYPE_PARENT.CLAIM_DOCS,
    image: '',
    progress: '',
  },
  {
    name: 'New Title Deed',
    type: DOCUMENT_TYPE.NEW_TITLE_DEED,
    parent: DOC_TYPE_PARENT.CLAIM_DOCS,
    image: '',
    progress: '',
  },
  {
    name: 'EJARI Contract',
    type: DOCUMENT_TYPE.EJARI_CONTRACT,
    parent: DOC_TYPE_PARENT.CLAIM_DOCS,
    image: '',
    progress: '',
  },
  {
    name: 'Title Deed of the Property',
    type: DOCUMENT_TYPE.TITLE_DEED,
    parent: DOC_TYPE_PARENT.CLAIM_DOCS,
    image: '',
    progress: '',
  },
  {
    name: 'SPA Or OQOOD',
    type: DOCUMENT_TYPE.SPA,
    parent: DOC_TYPE_PARENT.CLAIM_DOCS,
    image: '',
    progress: '',
  },
];

export const OFF_PLAN_TYPE = {
  PRIMARY: '1',
  SECONDARY: '2',
};

export const PDF_UPLOAD_TYPE = {
  FLOOR_PLAN: 1,
  BROCHURE: 2,
  SPA: 3,
  FORM_A: 4,
  ADVERTISEMENT: 5,
};
